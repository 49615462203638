<template>
  <div class="all-courses">
    <!--引入公共组件，左侧和顶部-->
    <!--实现左侧的导航栏效果-->
    <div id="content">
      <div id="navigation-bar">
        <div class="catalog-bar" @click="showOrHide('learning')">
          <div class="catalog-bar-rectangle" style="background: #407aff"></div>
          <div class="catalog-bar-title">学习中课程</div>
          <img
            v-if="learning"
            src="@/assets/all-course/icon-sjt-nor@2x.png"
            alt="开关标签"
            class="catalog-bar-title-switch"
          />
          <img
            v-else
            src="@/assets/all-course/icon-xjt-nor@2x.png"
            alt="开关标签"
            class="catalog-bar-title-switch"
          />
        </div>

        <div id="learning" v-if="learning">
          <!--每个展示项，都使用v-for进行渲染，id看情况选用数据id还是自己拼接，还有图片的src，以及课件的题目,v-bind:key="item"-->
          <div
            class="class-item"
            v-for="(items, index) in allClassInfos.learning"
            :key="index"
            :id="items.id"
            @click="classSelected(items.id, items.student_amount)"
            :class="{ 'class-item-selected-color': itemSelected == items.id }"
          >
            <img :src="items.imgPath" alt="课程图标" class="class-icon" />
            <div class="class-title">{{ items.name }}</div>
          </div>
        </div>

        <div class="catalog-bar" @click="showOrHide('ended')">
          <div class="catalog-bar-rectangle" style="background: #eb5c56"></div>
          <div class="catalog-bar-title">已结束课程</div>
          <img
            v-if="ended"
            src="@/assets/all-course/icon-sjt-nor@2x.png"
            alt="开关标签"
            class="catalog-bar-title-switch"
          />
          <img
            v-else
            src="@/assets/all-course/icon-xjt-nor@2x.png"
            alt="开关标签"
            class="catalog-bar-title-switch"
          />
        </div>

        <div id="ended" v-if="ended">
          <div
            class="class-item"
            v-for="(items, index) in allClassInfos.ended"
            :key="index"
            :id="items.id"
            @click="classSelected(items.id, items.student_amount)"
            :class="{ 'class-item-selected-color': itemSelected == items.id }"
          >
            <img :src="items.imgPath" alt="课程图标" class="class-icon" />
            <div class="class-title">{{ items.name }}</div>
          </div>
        </div>

        <div class="catalog-bar" @click="showOrHide('unstart')">
          <div class="catalog-bar-rectangle" style="background: #887beb"></div>
          <div class="catalog-bar-title">未开始课程</div>
          <img
            v-if="unstart"
            src="@/assets/all-course/icon-sjt-nor@2x.png"
            alt="开关标签"
            class="catalog-bar-title-switch"
          />
          <img
            v-else
            src="@/assets/all-course/icon-xjt-nor@2x.png"
            alt="开关标签"
            class="catalog-bar-title-switch"
          />
        </div>

        <div id="unstart" v-if="unstart">
          <div
            class="class-item"
            v-for="(items, index) in allClassInfos.unstart"
            :key="index"
            :id="items.id"
            @click="classSelected(items.id, items.student_amount)"
            :class="{ 'class-item-selected-color': itemSelected == items.id }"
          >
            <img :src="items.imgPath" alt="课程图标" class="class-icon" />
            <div class="class-title">{{ items.name }}</div>
          </div>
        </div>
      </div>
      <!--展示课程的具体课节情况-->
      <div id="courses-detail">
        <div v-if="courseClassNum">
          <div v-if="courseDetailInfo.learning.length > 0">
            <div class="courses-detail-info">
              <img
                src="@/assets/all-course/icon-skz-nor@2x.png"
                alt="上课中"
                class="circle-icon"
              />
              <div class="courses-type">
                上课中课节（{{ courseDetailInfo.learning.length }}节）
              </div>
            </div>
            <div
              v-for="(item, index) in courseDetailInfo.learning"
              :key="index"
              class="card-box"
            >
              <CurriculumCard :content="item"> </CurriculumCard>
            </div>
          </div>

          <div v-if="courseDetailInfo.ended.length > 0">
            <div class="courses-detail-info">
              <img
                src="@/assets/all-course/icon-yjs-nor@2x.png"
                alt="已结束"
                class="circle-icon"
              />
              <div class="courses-type">
                已结束课节（{{ courseDetailInfo.ended.length }}节）
              </div>
            </div>
            <div
              v-for="(item, index) in courseDetailInfo.ended"
              :key="index"
              class="card-box"
            >
              <CurriculumCard :content="item"> </CurriculumCard>
            </div>
          </div>

          <div v-if="courseDetailInfo.unstart.length > 0">
            <div class="courses-detail-info">
              <img
                src="@/assets/all-course/icon-wks-nor@2x.png"
                alt="未开始"
                class="circle-icon"
              />
              <div class="courses-type">
                未开始课节（{{ courseDetailInfo.unstart.length }}节）
              </div>
            </div>
            <div
              v-for="(item, index) in courseDetailInfo.unstart"
              :key="index"
              class="card-box"
            >
              <CurriculumCard :content="item"> </CurriculumCard>
            </div>
          </div>
        </div>
        <!--如果上述元素都不渲染的话，渲染下方元素-->
        <el-empty
          class="empty-status"
          image="http://rts-1251489922.cos.ap-shanghai.myqcloud.com/img/1630547660547.png"
          description="暂无课程"
          v-else
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
//需要引入其他组件或者功能时，在此处使用import引入
import {
  getCourseList,
  getanswering,
  getClassRoom,
  getCourseDataTimeByIds,
  getBindInfo,
  getCourseConfByAssistant,
} from "@/api/api.js";
import { formateDate } from "@/utils/common.js";
import CurriculumCard from "@/components/curriculumCard/index";

export default {
  components: { CurriculumCard },

  //数据属性定义
  data() {
    return {
      learning: true,
      ended: false,
      unstart: false,
      //控制导航栏选中效果
      itemSelected: "",
      //某个课程的人数
      studentAmount: 0,
      //页面加载完成时候调用接口返回的所有课程的数据
      allClassInfos: {
        learning: [],
        ended: [],
        unstart: [],
      },
      //每一个课程的所有课节信息
      courseDetailInfo: {
        learning: [],
        ended: [],
        unstart: [],
      },
      //各种科目的导航栏封面
      subjectIconList: [
        require("../../assets/all-course/tky-kcbg1-nor@2x.png"),
        require("../../assets/all-course/tky-kcbg2-nor@2x.png"),
        require("../../assets/all-course/tky-kcbg3-nor@2x.png"),
        require("../../assets/all-course/tky-kcbg4-nor@2x.png"),
        require("../../assets/all-course/tky-kcbg5-nor@2x.png"),
        require("../../assets/all-course/tky-kcbg6-nor@2x.png"),
      ],
    };
  },
  methods: {
    showOrHide(id) {
      if (id === "learning") {
        this.learning = !this.learning;
      }
      if (id === "ended") {
        this.ended = !this.ended;
      }
      if (id === "unstart") {
        this.unstart = !this.unstart;
      }
    }, // 获取答疑课接口
    getanswering() {
      let data = {
        course_id: this.itemSelected,
      };
      let temp = [];
      getanswering(data).then((res) => {
        let resData = res.data;
        if (resData.code === 0) {
          let data = resData.data;
          let cardList = [];
          for (let value of data) {
            let dataObj = {
              id: value.id,
              // 所属哪天用于判断是否显示 YYYY-mm-dd
              belonging_day: formateDate(
                "YYYY-mm-dd",
                Number(value.date) + Number(value.start_time) * 1000
              ),
              // 讲义名称 有就显示讲义名，没有展示课程名
              handout_name: value.name,
              name: value.name, // 课程名称
              course_name: value.course_name, // 所属课程
              serial: value.talk_cloud_info?.serial, // 拓客云的serial 用于去上课获取上课地址
              teacher_pwd: value.talk_cloud_info?.teacher_pwd,
              assistant_pwd: value.talk_cloud_info?.assistant_pwd,
              patrol_pwd: value.talk_cloud_info?.patrol_pwd,
              class_time_text: formateDate(
                "YYYY/mm/dd HH:MM",
                Number(value.date) + Number(value.start_time) * 1000
              ), // 上课时间 字符串
              course_duration: "", // 课程时长
              class_time_start:
                Number(value.date) + Number(value.start_time) * 1000, // 上课时间 时间戳毫秒
              class_time_end:
                Number(value.date) + Number(value.end_time) * 1000, // 下课时间 时间戳毫秒
              now_time: new Date().getTime(), // 当前时间
              stu_num: this.studentAmount, // 学生人数
              // 课程状态： 0 未上课  1 上课中  2 教学报告
              status: this.isCurrStatus(
                Number(value.date) + Number(value.start_time) * 1000,
                Number(value.date) + Number(value.end_time) * 1000,
                new Date().getTime()
              ),
            };
            // 所有班课卡片数据数组
            if (value.platform == 1) {
              cardList.push(dataObj);
            }
          }
          temp = cardList;
          this.getClassRoom(temp);
        }
      });
    },
    // 获取班课接口
    getClassRoom(temp) {
      let data = {
        course_id: this.itemSelected,
      };

      getClassRoom(data).then((res) => {
        let resData = res.data;
        if (resData.code === 0) {
          let data = resData.data;
          // let CardArr = []
          for (let value of data) {
            // 列表数据处理，传给卡片组件
            let dataObj = {
              id: value.id,
              belonging_day: value.attends_date, // 所属哪天用于判断是否显示 YYYY-mm-dd
              // 讲义名称 有就显示讲义名，没有展示课程名
              handout_name: value.name,
              name: value.name, // 课程名称
              course_name: value.course_name, // 所属课程
              serial: value.talk_cloud_info?.serial, // 拓客云的serial 用于去上课获取上课地址
              teacher_pwd: value.talk_cloud_info?.teacher_pwd,
              assistant_pwd: value.talk_cloud_info?.assistant_pwd,
              patrol_pwd: value.talk_cloud_info?.patrol_pwd,
              class_time_text: `${this.format(3, value.attends_date)} ${
                value.start_text
              }`, // 上课时间 字符串
              course_duration: "", // 课程时长
              class_time_start:
                (Number(value.attends_date_time) + Number(value.start_time)) *
                1000, // 上课时间 时间戳毫秒
              class_time_end:
                (Number(value.attends_date_time) + Number(value.end_time)) *
                1000, // 下课时间 时间戳毫秒
              now_time: new Date().getTime(), // 当前时间
              stu_num: this.studentAmount, // 学生人数
              // 课程状态： 0 未上课  1 上课中  2 教学报告
              status: this.isCurrStatus(
                (Number(value.attends_date_time) + Number(value.start_time)) *
                  1000,
                (Number(value.attends_date_time) + Number(value.end_time)) *
                  1000,
                new Date().getTime()
              ),
            };
            // 所有班课卡片数据数组
            if (value.platform == 1) {
              temp.push(dataObj);
            }
          }
          let classroomIds = [];
          //开始处理数据
          temp.forEach(function (value) {
            classroomIds.push(value.id);
          });

          this.getBindInfo(classroomIds, temp);
        }
      });
    },
    // 请求讲义接口
    getBindInfo(classroomIds, temp) {
      let data = {
        classroom: classroomIds,
      };
      let that = this;

      //临时中转数据
      let courseDetailInfoTemp = {
        learning: [],
        ended: [],
        unstart: [],
      };
      getBindInfo(data).then((res) => {
        if (res.data.code === 0) {
          // 匹配课节id添加讲义名
          for (let jyitem of res.data.data) {
            for (let allItem of temp) {
              if (jyitem.classroom === allItem.id) {
                allItem.handout_name = jyitem.handout_name;
              }
            }
          }
          temp.forEach(function (value) {
            if (value.status == 0) {
              courseDetailInfoTemp.unstart.push(value);
            } else if (value.status == 1) {
              courseDetailInfoTemp.learning.push(value);
            } else if (value.status == 2) {
              courseDetailInfoTemp.ended.push(value);
            }
          });
          that.courseDetailInfo = [];
          that.courseDetailInfo = courseDetailInfoTemp;
        }
      });
    },
    classSelected(id, studentAmount) {
      //点击某个课程的时候还需要传一个参数，就是班级的人数
      this.$nextTick(() => {
        this.itemSelected = id;
        this.studentAmount = studentAmount;
        this.courseDetailInfo = {
          learning: [],
          ended: [],
          unstart: [],
        };
        this.getanswering();
      });
    },
    // 判断课程状态
    isCurrStatus(start, end, now) {
      // 当前时间小于开始时间  未开课
      if (now < start) {
        return 0;
      } else if (start < now && now < end) {
        // 当前时间大于开始时间 并且 小于结束时间  开课中
        return 1;
      } else if (end < now) {
        // 当前时间大于结束时间  已结课
        return 2;
      }
    },
    // 格式化日期 type = 0 格式化日期  type = 1 上个月日期   type = 2 下个月日期 type = 3  格式化日期 2021/09/01
    format(type, date) {
      date = new Date(date);
      if (type == 0) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      }
      if (type == 3) {
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      }
      // 上月20号
      if (type == 1) {
        // 当前时间戳
        let date1 = new Date(date); // 当前日期
        // 当前一号时间戳
        let timestamp = new Date(
          `${date1.getFullYear()}-${date1.getMonth() + 1}-01`
        ).getTime();
        // 上月最后七天时间戳
        let resTimestamp = timestamp - 1000 * 60 * 60 * 24 * 7;
        let resDate = new Date(resTimestamp);
        return `${resDate.getFullYear()}-${
          resDate.getMonth() + 1
        }-${resDate.getDate()}`;
      }
      // 下月 7号
      if (type == 2) {
        let endDate = new Date(date); // 当前日期
        let currentMonth = endDate.getMonth();
        let nextMonth = ++currentMonth;
        let nextMonthFirstDay = new Date(endDate.getFullYear(), nextMonth, 1);
        let oneDay = 1000 * 60 * 60 * 24;
        let lastTime = new Date(nextMonthFirstDay - oneDay);
        let endMonth = parseInt(lastTime.getMonth() + 1);
        let endDay = lastTime.getDate();
        if (endMonth < 10) {
          endMonth = "0" + endMonth;
        }
        if (endDay < 10) {
          endDay = "0" + endDay;
        }
        // 本月最后一天字符串 2021-09-30
        let resTxet = `${endDate.getFullYear()}-${endMonth}-${endDay}`;
        let timestamp = new Date(resTxet);
        let resTimestamp = Number(timestamp) + 1000 * 60 * 60 * 24 * 7;
        let resDate = new Date(resTimestamp);
        return `${resDate.getFullYear()}-${
          resDate.getMonth() + 1
        }-${resDate.getDate()}`;
      }
    },
  },
  mounted: function () {
    //该部分被加载的时候
    if (this.$store.state.role.id == 3) {
      this.$router.push({ name: "home" });
      return;
    }
    if (this.$store.state.role.id == 2) {
      // 根据班主任获取批量设置的值，再查询课程
      let data = {
        need_student_amount: true,
      };
      let allClassInfos = {
        learning: [],
        ended: [],
        unstart: [],
      };

      getCourseConfByAssistant({
        assistant: this.$store.state.userInfo.id,
      }).then((conf) => {
        console.log(conf);
        conf = conf.data.data;
        if (!Array.isArray(conf)) {
          return;
        }
        data.id = conf.map((item) => item.course_id);
        getCourseList(data).then((res) => {
          //查询成功
          if (res.data.code === 0) {
            //将所有的信息进行遍历
            let ids = [];
            let allCourse = res.data.data;
            let dealAllCourse = [];
            allCourse.forEach(function (value) {
              if (value.platform == 1) {
                ids.push(value.id);
                dealAllCourse.push(value);
              }
            });
            allCourse = dealAllCourse;
            //课程id数组对象
            let idsData = {
              course_id: ids,
            };
            getCourseDataTimeByIds(idsData).then((res) => {
              if (res.data.code === 0) {
                let coursesTime = res.data.data;
                let coursesTimeMap = {};
                //首先处理一下课程的时间集合
                coursesTime.forEach(function (value) {
                  let id = value.course_id;
                  coursesTimeMap[id] = value;
                });

                //生成今天的日期字符串
                let today = formateDate("YYYY-mm-dd", new Date());
                //获取到所有班级的课程
                for (let i = 0; i < allCourse.length; i++) {
                  let random = Math.floor((Math.random() + 1) * 6) - 6;
                  allCourse[i].imgPath = this.subjectIconList[random];
                  //判定每个课的开始结束时间
                  if (
                    coursesTimeMap[allCourse[i].id].start_date == "" ||
                    coursesTimeMap[allCourse[i].id].end_date == ""
                  ) {
                    allClassInfos.learning.push(allCourse[i]);
                  } else {
                    if (coursesTimeMap[allCourse[i].id].end_date < today) {
                      allClassInfos.ended.push(allCourse[i]);
                    } else if (
                      coursesTimeMap[allCourse[i].id].start_date > today
                    ) {
                      allClassInfos.unstart.push(allCourse[i]);
                    } else {
                      allClassInfos.learning.push(allCourse[i]);
                    }
                  }
                }
                this.allClassInfos = allClassInfos;
              }
            });
          }
        });
      });

      return;
    }
    let data = {
      teacher: this.$store.state.userInfo.id,
      need_student_amount: true,
    };
    let allClassInfos = {
      learning: [],
      ended: [],
      unstart: [],
    };
    getCourseList(data).then((res) => {
      //查询成功
      if (res.data.code === 0) {
        //将所有的信息进行遍历
        let ids = [];
        let allCourse = res.data.data;
        let dealAllCourse = [];
        allCourse.forEach(function (value) {
          if (value.platform == 1) {
            ids.push(value.id);
            dealAllCourse.push(value);
          }
        });
        allCourse = dealAllCourse;
        //课程id数组对象
        let idsData = {
          course_id: ids,
        };
        getCourseDataTimeByIds(idsData).then((res) => {
          if (res.data.code === 0) {
            let coursesTime = res.data.data;
            let coursesTimeMap = {};
            //首先处理一下课程的时间集合
            coursesTime.forEach(function (value) {
              let id = value.course_id;
              coursesTimeMap[id] = value;
            });

            //生成今天的日期字符串
            let today = formateDate("YYYY-mm-dd", new Date());
            //获取到所有班级的课程
            for (let i = 0; i < allCourse.length; i++) {
              let random = Math.floor((Math.random() + 1) * 6) - 6;
              allCourse[i].imgPath = this.subjectIconList[random];
              //判定每个课的开始结束时间
              if (
                coursesTimeMap[allCourse[i].id].start_date == "" ||
                coursesTimeMap[allCourse[i].id].end_date == ""
              ) {
                allClassInfos.learning.push(allCourse[i]);
              } else {
                if (coursesTimeMap[allCourse[i].id].end_date < today) {
                  allClassInfos.ended.push(allCourse[i]);
                } else if (coursesTimeMap[allCourse[i].id].start_date > today) {
                  allClassInfos.unstart.push(allCourse[i]);
                } else {
                  allClassInfos.learning.push(allCourse[i]);
                }
              }
            }
            this.allClassInfos = allClassInfos;
          }
        });
      }
    });
  },
  computed: {
    //计算课程的课节总数
    courseClassNum: function () {
      let sum =
        this.courseDetailInfo.learning.length +
        this.courseDetailInfo.ended.length +
        this.courseDetailInfo.unstart.length;
      return sum > 0;
    },
  },
};
</script>

<style type="scss" scoped>
.all-courses {
  height: 100%;
  width: 100%;
  padding: 24px;
}

/*实际内容包裹*/
#content {
  height: 100%;
  width: 100%;
}

/*左侧的课程导航栏*/
#navigation-bar {
  width: 360px;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  /*允许出现右侧的滚动条*/
  overflow-y: auto;
  float: left;
}

/*父级导航栏样式*/
.catalog-bar {
  height: 65px;
  cursor: pointer;
}

/*父级导航栏的左侧矩形颜色*/
.catalog-bar .catalog-bar-rectangle {
  margin-left: 20px;
  margin-top: 23px;
  height: 20px;
  width: 6px;
  float: left;
}

/*父级导航栏标题*/
.catalog-bar .catalog-bar-title {
  margin-top: 20px;
  margin-left: 12px;
  height: 25px;
  width: 93px;
  /* font-family: "苹方-简 中黑体"; */
  font-size: 18px;
  /*设置圆角*/
  border-radius: 4px;
  /*设置文字行高*/
  line-height: 25px;
  /*文件不换行*/
  white-space: nowrap;
  float: left;
}

.catalog-bar .catalog-bar-title-switch {
  height: 14px;
  width: 14px;
  margin-top: 26px;
  margin-right: 24px;
  float: right;
}

.class-item {
  height: 100px;
  display: flex;
  align-items: center;
  /*需要设置选中后改变颜色*/
}

/*导航栏被选中后的颜色*/
.class-item-selected-color {
  background: #f4f4f4;
}

.class-item .class-icon {
  width: 107px;
  height: 68px;
  margin: 0 20px 0 30px;
}
.class-item .class-title {
  width: 168px;
  /*设置文本内容最多为两行，溢出信息为...*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /*todo 设置文本字体*/
  /* font-family: "苹方-简 常规体"; */
  font-size: 16px;
  line-height: 22px;
}

/*开始写课节详细信息展示部分*/
#courses-detail {
  width: calc(100% - 360px);
  height: 100%;
  /*border: blue solid 1px;*/
  float: left;
  position: relative;
  padding: 24px 0 0 24px;
  /* margin-left: 24px; */
  /*应该也要支持滑动*/
  overflow-y: auto;
}

.empty-status {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.courses-detail-info {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}

.courses-detail-info .circle-icon {
  height: 30px;
  width: 30px;
}

.courses-detail-info .courses-type {
  height: 25px;
  margin-left: 10px;
  line-height: 25px;
  font-size: 18px;
  /* font-family: "苹方-简 中黑体"; */
}

.card-box {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
